import { DragDropModule } from '@angular/cdk/drag-drop';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsModule } from '@angular/material/chips';
import { MatLineModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
import { NgxsModule } from '@ngxs/store';
import { ContactDetailsComponent } from '@shared/components/contact-details/contact-details.component';
import { ContactViewComponent } from '@shared/components/contact-view/contact-view.component';
import { MapDrawGeoFenceComponent } from '@shared/components/map-draw-geo-fence/map-draw-geo-fence.component';
import { MapLocationSearchComponent } from '@shared/components/map-location-search/map-location-search.component';
import { MapComponent } from '@shared/components/map/map.component';
import { MultiSelectDropDownComponent } from '@shared/components/multi-select-drop-down/multi-select-drop-down.component';
import { ZeroPadPipe } from '@shared/pipes/zero-pad.pipe';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ContactNumbersComponent } from './components/contact-numbers/contact-numbers.component';
import { DocumentAttachmentWizardComponent } from './components/document-attachment-wizard/document-attachment-wizard.component';
import { FilePreviewComponent } from './components/file-preview/file-preview.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderBarComponent } from './components/header-bar/header-bar.component';
import { ImageAttachmentWizardComponent } from './components/image-attachment-wizard/image-attachment-wizard.component';
import { KeyValueComponent } from './components/key-value/key-value.component';
import { NotificationDrawerComponent } from './components/notification-drawer/notification-drawer.component';
import { RelativeTimePipe } from './components/notification-drawer/pipes/relative-time.pipe';
import { NotificationState } from './components/notification-drawer/state/notification.state';
import { NotificationComponent } from './components/notification/notification.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { PrincipleContactsComponent } from './components/principle-contacts/principle-contacts.component';
import { RegisteredAddressComponent } from './components/registered-address/registered-address.component';
import { RejectDialogComponent } from './components/reject-dialog/reject-dialog.component';
import { SideBarComponent } from './components/side-bar/side-bar.component';
import { UploadedOrgDocumentComponent } from './components/uploaded-org-document/uploaded-org-document.component';
import { YouAreHereComponent } from './components/you-are-here/you-are-here.component';
import { DefaultDecimalPlacesCommaDirective } from './directives/default-decimal-places-comma.directive';
import { PositiveNumberInputDirective } from './directives/positive-number-input.directive';
import { AuthorizePipe } from './pipes/authorize.pipe';
import { CapitalizePipe } from './pipes/capitalize-words.pipe';
import { StatusToLowerPipe } from './pipes/status-to-lower.pipe';
// do not import any service here, because it will create multiple service instances unless it is acceptable
// do not import this to app module
// do import this module in any other feature module

@NgModule({
  declarations: [
    NotificationComponent,
    NotificationDrawerComponent,
    PaginatorComponent,
    HeaderBarComponent,
    RelativeTimePipe,
    SideBarComponent,
    AlertDialogComponent,
    CapitalizePipe,
    MultiSelectDropDownComponent,
    ZeroPadPipe,
    StatusToLowerPipe,
    ContactDetailsComponent,
    MapComponent,
    MapLocationSearchComponent,
    MapDrawGeoFenceComponent,
    ContactViewComponent,
    ImageAttachmentWizardComponent,
    DocumentAttachmentWizardComponent,
    FooterComponent,
    ContactNumbersComponent,
    AuthorizePipe,
    RegisteredAddressComponent,
    PrincipleContactsComponent,
    ConfirmationDialogComponent,
    YouAreHereComponent,
    PositiveNumberInputDirective,
    RejectDialogComponent,
    KeyValueComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    MatToolbarModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatIconModule,
    MatNativeDateModule,
    MatLineModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatChipsModule,
    MatTreeModule,
    MatPaginatorModule,
    MatChipsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DefaultDecimalPlacesCommaDirective,
    NgxsModule.forFeature([NotificationState]),
    UploadedOrgDocumentComponent,
    FilePreviewComponent,
  ],
  exports: [
    // export any modules, components, pipes and etc.
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // HttpClientModule,
    MatGridListModule,
    MatSlideToggleModule,
    MatCardModule,
    MatRadioModule,
    MatCheckboxModule,
    MatToolbarModule,
    MatButtonModule,
    MatSelectModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSidenavModule,
    MatListModule,
    MatNativeDateModule,
    MatLineModule,
    MatDatepickerModule,
    MatInputModule,
    MatIconModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatTabsModule,
    MatChipsModule,
    MatDialogModule,
    MatTableModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTreeModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatPaginatorModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    MatListModule,
    MatPaginatorModule,
    DragDropModule,
    MatToolbarModule,
    MatBadgeModule,
    NotificationDrawerComponent,
    PaginatorComponent,
    NotificationComponent,
    HeaderBarComponent,
    SideBarComponent,
    CapitalizePipe,
    MultiSelectDropDownComponent,
    ZeroPadPipe,
    ContactDetailsComponent,
    ContactViewComponent,
    MapLocationSearchComponent,
    MapDrawGeoFenceComponent,
    ImageAttachmentWizardComponent,
    DocumentAttachmentWizardComponent,
    // FooterComponent,
    ContactNumbersComponent,
    AuthorizePipe,
    RegisteredAddressComponent,
    PrincipleContactsComponent,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ConfirmationDialogComponent,
    YouAreHereComponent,
    DefaultDecimalPlacesCommaDirective,
    UploadedOrgDocumentComponent,
    FilePreviewComponent,
    StatusToLowerPipe,
    PositiveNumberInputDirective,
    RejectDialogComponent,
    KeyValueComponent,
  ],
  providers: [
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,

      useValue: {
        separatorKeyCodes: [ENTER, COMMA],
      },
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {},
    },
    { provide: MatSnackBarRef, useValue: {} },
  ],
})
export class SharedModule {}

import { Component, ElementRef, inject, signal, viewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { UploadedFileType } from 'app/modules/wizard/models/uploaded-file.type';
import { FilePreviewComponent } from '../file-preview/file-preview.component';

@Component({
  selector: 'app-org-document-preview-dialog',
  templateUrl: './org-document-preview-dialog.component.html',
  styleUrls: ['./org-document-preview-dialog.component.scss'],
  standalone: true,
  imports: [FilePreviewComponent, MatButtonModule, MatIconModule],
})
export class OrgDocumentPreviewDialogComponent {
  data = inject<{ file: UploadedFileType }>(MAT_DIALOG_DATA);
  file = signal<UploadedFileType>(this.data.file);

  dialogElement = viewChild.required<ElementRef<HTMLDivElement>>('orgDocumentPreviewDialog');

  isFullScreen = signal(false);

  goFullScreen() {
    const element = this.dialogElement();
    if (this.isFullScreen()) {
      document.exitFullscreen();
    } else {
      element.nativeElement.requestFullscreen();
    }
    this.isFullScreen.update(value => !value);
  }
}
